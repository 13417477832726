<!--
 * @Descripttion: teacher.dingdingkaike
 * @version: 1.1pc
 * @Author: ivan
 * @Date: 2019-10-30 10:34:30
 * @LastEditors: sueRimn
 * @LastEditTime: 2019-10-30 10:59:15
 -->
<template>
  <div class="wrap">
    <div v-html="htmlStr"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      htmlStr: '',
    }
  },
  created() {
    this.getProtocol()
  },
  methods: {
    /**
     * @name: getProtocol
     * @test: test font
     * @msg: 获取用户协议
     * @param {none}
     * @return: none
     */
    getProtocol() {
      this.$root.$http({
        url: '/Organ/organAttachment',
        name: 'organAttachment',
        data: {
          type: this.$route.query.type,
        },
        callback: ({ data }) => {
          this.htmlStr = data
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 20px;
  font-size: 15px;
  line-height: 1.7;
  color: #333;
}
</style>
